import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import axios from "axios";
import { useStore } from './services/store';
import { useFetchActor } from "./services/getData";
import Layout from "./Layout";
import Prosjekt from './pages/prosjekt';
import Sak from './pages/sak';
import Sesong from './pages/sesong';
import Aktoer from './pages/aktoer';
import Drone from './pages/drone';
import HvemHvor from './pages/hvemHvor';
import Postliste from './pages/postliste';
import MinSide from './pages/minside';
import NoPage from "./pages/NoPage";


export default function App() {
  console.log("app.js rendered.")
  const { keycloak, initialized } = useKeycloak();
  const setUserRole = useStore(state => state.setUserRole);
  const setUserData = useStore(state => state.setUserData);
  const fetchActorData = useFetchActor();  


  // Setup the Axios interceptor
  useEffect(() => {
    // Ensure the interceptor is set up only once
    const interceptor = axios.interceptors.request.use(config => {
      if (keycloak.token) {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });
    
    // Eject the interceptor when component unmounts
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []); // Empty dependency array to set it up only on mount

  // Token refresh logic
  useEffect(() => {
    if (keycloak.authenticated) {
      const refreshInterval = setInterval(() => {
        keycloak.updateToken(70) // Set minValidity to a reasonable time, like 70 seconds before it expires
          .then(refreshed => {
            if (refreshed) {
              console.log('Token was successfully refreshed');
            } else {
              console.log('Token is still valid');
            }
          })
          .catch(error => {
            console.error('Failed to refresh token', error);
          });
      }, 60000); // Check every minute as an example

      // Clean up interval on component unmount
      return () => clearInterval(refreshInterval);     
    }
  }, [keycloak.authenticated, keycloak.token]);  


  useEffect(() => {
    if (keycloak.authenticated) {
      // get user role from Keycloak
      setUserRole(keycloak.resourceAccess?.pdb?.roles[0])
      setUserData(keycloak.tokenParsed);
      fetchActorData(keycloak.tokenParsed?.email);
    }
  }, [keycloak.token, fetchActorData]);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/Sak" replace />} />
          <Route path="Prosjekt" element={<Prosjekt />} >
            <Route path=":id" element={<Prosjekt />} />
          </Route>
          <Route path="Sak" element={<Sak />} >
            <Route path=":id" element={<Sak />} />
          </Route>
          <Route path="Sesong" element={<Sesong />} >
            <Route path=":id" element={<Sesong />} />
          </Route>
          <Route path="Aktør" element={<Aktoer />} >
            <Route path=":id" element={<Aktoer />} />
          </Route>
          <Route path="Flygning" element={<Drone />} >
            <Route path=":id" element={<Drone />} />
          </Route>          
          <Route path="HvemHvaHvor" element={<HvemHvor />} />
          <Route path="Postliste" element={<Postliste />} />
          <Route path="MinSide" element={<MinSide />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}