import { parseISO, format } from 'date-fns';

/**
 * Parses a PostgreSQL daterange string and converts it to an array of JavaScript Date objects.
 *
 * @param {string} range - The PostgreSQL daterange string, e.g., '[2012-06-01,2013-09-15)'.
 * @returns {Array<Date|null>} An array containing the start and end dates as Date objects.
 *                             If the range is null or an empty string, returns [null, null].
 */
export const parsePostgresDateRange = (range) => {
    if (!range || range === '') {
        return [null, null];
    }

    const strippedRange = range.replace(/[\[\]\(\)]/g, '');
    const [startDateString, endDateString] = strippedRange.split(',');
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    return [startDate, endDate];
};

/**
 * Formats an array of two JavaScript Date objects into a PostgreSQL daterange string.
 *
 * Validates dates to ensure they can be properly formatted to string.
 * 
 * @param {Array<Date|null>} dateRange - An array containing the start and end dates as Date objects.
 *                                       If a date is invalid or null, it will be represented as an empty string in the output.
 * @returns {string} The formatted PostgreSQL daterange string, e.g., '[2012-06-01,2013-09-15)'.
 */
export const formatToPostgresDateRange = ([startDate, endDate]) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
  
    const start = isValidDate(startDate) ? startDate.toISOString().split('T')[0] : '';
    const end = isValidDate(endDate) ? endDate.toISOString().split('T')[0] : '';
  
    return `[${start},${end})`;
  };

/**
 * Parses a PostgreSQL timestamp string into a Date object.
 * @param {string} timestamp - The timestamp string from PostgreSQL.
 * @returns {Date} The parsed Date object.
 */
export const parseTimestamp = (timestamp) => {
  return parseISO(timestamp);
};

/**
 * Formats a Date object into a PostgreSQL timestamp string.
 * @param {Date} date - The Date object to format.
 * @returns {string} The formatted timestamp string for PostgreSQL.
 */
export const formatTimestamp = (date) => {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

/**
 * Parses a PostgreSQL time string into a JavaScript Date object suitable for MUI TimePicker.
 * @param {string} time - The time string from PostgreSQL, e.g., '13:45:30'.
 * @returns {Date} The parsed Date object.
 */
export const parsePostgresTime = (time) => {
  const [hours, minutes, seconds] = time.split(':');
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  return date;
};

/**
 * Formats a JavaScript Date object into a PostgreSQL time string.
 * @param {Date} date - The Date object to format.
 * @returns {string} The formatted time string for PostgreSQL, e.g., '13:45:30'.
 */
export const formatPostgresTime = (date) => {
  console.log("date", date)
  return format(date, 'HH:mm:ss');
};
