import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { LoadingMessage } from './Loading';
import { useStore } from '../services/store';
import { makeRequest } from '../services/query';
import { EngasjementList, EngasjementModal } from './Engasjement';
import { RelSak, RelProsjekt } from '../components/SakModules';
import KeyValueEditor from './KeyValueEditor';
import useCrudActions from './crudActions';
import { 
  InputText, 
  InputTextMulti, 
  InputDate,
  CheckInput
} from '../components/InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';

const AktoerIngen = () => (
  <Typography paragraph>
    Ingen aktør er valgt. Opprett ny, eller velg eksisterende aktør i tabellen.
  </Typography>
);

function RolleForm({selectedRowData, setSelectedRowData, roller}) {
  const rolle = selectedRowData.roller || {}
  const selectedItems = roller.filter(r => rolle[r.value]);

  const handleChange = (_, newValue) => {
    const newObj = roller.reduce((acc, curr) => ({...acc, [curr.value]: newValue.includes(curr)}), {});
    setSelectedRowData({...selectedRowData, roller: newObj});
  }

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={roller}
      getOptionLabel={(option) => option.label}
      value={selectedItems}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Roller" />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
        ))
      }
    />
  );
}

export default function  AktoerEdit ({ apiTable, editTable, apiId }) {
  console.log("Render AktørEdit")

  const selectedAktoerUUID = useStore(state => state.selectedAktoerUUID);
  const setSelectedAktoerUUID = useStore(state => state.setSelectedAktoerUUID);
  const [loading, setLoading] = useState(false);
  const [roller, setRoller] = useState([]);
  const [engasjement, setEngasjement] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [engasjementData, setEngasjementData] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({}); 
  const setStatus = useStore(state => state.setStatus);
  const [prosjektData, setProsjektData] = useState([]);   
  const [sakData, setSakData] = useState([]);   

  let { id } = useParams();
  const navigate = useNavigate();

  const getListData = async () => {
    setLoading(true);
    try {
      const [rolleList, engasjementList, prosjektDataList, sakDataList ] = await Promise.all([
        makeRequest('get', 'list_rolle', {}, {}),
        makeRequest('get', `v_engasjement?ansatt_id=eq.${id}`, {}, {}),
        makeRequest(
          'get', 
          `v_prosjekt?select=uuid,prosjektkode,prosjektnavn,prosjektleder,askeladden_id,rapport_ferdig,regnskap_signert,feltarbeid_avslutta&tiltakshaver_uuid=eq.${id}`, 
          {}, 
          {}
        ),
        makeRequest(
          'get', 
          `v_sak?select=uuid,saksnavn,saksnummer,saksbehandler,paragraf,sak_inn,kommentar,frist,sted_samlet,ask_id&tiltakshaver_uuid=eq.${id}`, 
          {}, 
          {}
        ),   
      ]);
      setRoller(rolleList);
      setEngasjement(engasjementList);
      setProsjektData(prosjektDataList);
      setSakData(sakDataList);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
    }
    setLoading(false);
  };




  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        console.log('Running handleRowselected')
        console.log(uuid)
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        setSelectedRowData(rowData[0] || {});
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }      
    } else {
      setSelectedRowData({});
    }
    setLoading(false);
  };

  //Cleaning data before update
  const { navn, ...cleanedRowData } = selectedRowData;
  // Retrieve the CRUD functions from the hook
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedAktoerUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedAktoerUUID,
    apiTable: editTable,
    navigate: navigate,   
  });

  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedAktoerUUID(id);
      handleRowSelected(id);
      getListData();      
    } else if(selectedAktoerUUID){
      navigate(selectedAktoerUUID);
      setLoading(false);
    };
    setStatus(null);
  }, [id]);

  const nyttEngasjement = () => {
    setEngasjementData({
      ansatt_id: selectedAktoerUUID,
      ansatt_kode: selectedRowData.ansatt_id
      })
    setIsModalOpen(true)
  };

  const adresseOptions = [
    { value: 'faktuaraadresse', label: 'Faktuaraadresse' },
    { value: 'e-fakturaadresse', label: 'E-fakturaadresse' },
    { value: 'besøksadresse', label: 'Besøksadresse' }
  ];

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  };

  if(!id){
    return <AktoerIngen />
  };

  return(
    <div>
      <Grid container spacing={2} >
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>
              {selectedRowData.organisasjon ? 'Organisasjonsinformasjon' : 'Personinformasjon'}
            </Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
              { selectedRowData.organisasjon &&
                <>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 8 }}>
                    <InputText
                      name="org_navn"
                      label="Organisasjonsnavn"
                      value={selectedRowData.org_navn}
                      onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }}>
                    <InputText
                      name="org_nummer"
                      label="Organisasjonsummer"
                      value={selectedRowData.org_nummer}
                      onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    />
                  </Grid>                  
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                    <Divider />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                    <Typography variant='h6'>Kontaktperson</Typography>  
                  </Grid>             
                </>
              }
                <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 5 }}>
                  <InputText
                    name="fornavn"
                    label="Fornavn"
                    value={selectedRowData.fornavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 5 }}>
                  <InputText
                    name="etternavn"
                    label="Etternavn"
                    value={selectedRowData.etternavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                { !selectedRowData.organisasjon &&
                  <>                
                    <Grid size={{ xs: 4, sm: 4, md: 6, lg: 4, xl: 2 }}>
                      <InputText
                        name="initial"
                        label="Initialer"
                        value={selectedRowData.initial}
                        onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                      />
                    </Grid>
                    <Grid size={{ xs: 8, sm: 8, md: 6, lg: 4, xl: 4 }}>
                      <InputDate
                        name="foedt_dato"
                        label="Født"
                        value={selectedRowData.foedt_dato}
                        onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                      />
                    </Grid>
                  </>
                }                
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                <CheckInput
                      name="organisasjon"
                      label="Organisasjon"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                    />                  
                  <Divider />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <KeyValueEditor
                    name={"telefon"}
                    jsonData={selectedRowData.telefon}
                    onSave={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    keyLabel={"Telefon-Type"}
                    valueLabel={"Nummer"}
                    addLabel={"Legg til telefon"}
                  />
                </Grid>                 
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <Divider />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <KeyValueEditor
                    name={"epost"}
                    jsonData={selectedRowData.epost}
                    onSave={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    keyLabel={"Epost-Type"}
                    valueLabel={"Adresse"}
                    addLabel={"Legg til Epost"}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <Divider />
                </Grid>                
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <KeyValueEditor
                    name={"adresse"}
                    jsonData={selectedRowData.adresse}
                    onSave={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    keyLabel={"Adresse-Type"}
                    valueLabel={"Adresse"}
                    addLabel={"Legg til Adresse"}
                    keyOptions={adresseOptions}
                    adresse
                  />
                </Grid>
              </Grid> 
            </form>
          </Paper>
        </Grid>
        <Grid size={{ sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
              <Typography variant='h6'>
                {selectedRowData.organisasjon ? 'Rolletilknytning' : 'Arbeidsforhold'}
              </Typography>
              <Grid container spacing={2} alignItems="center" >
                { !selectedRowData.organisasjon &&
                  <>                
                    <Grid size={{ xs: 12, sm: 4, md: 4, lg: 3, xl: 4 }}>
                      <InputText
                        name="ansattnr"
                        label="Ansattnummer"
                        value={selectedRowData.ansattnr}
                        onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                      />
                    </Grid>              
                    <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                      <InputText
                        name="avdeling"
                        label="Avdeling"
                        value={selectedRowData.avdeling}
                        onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 2, md: 2, lg: 3, xl: 2 }}>
                      <CheckInput
                        name="aktiv"
                        label="Aktiv feltarbeider"
                        currData={selectedRowData}
                        setCurrData={setSelectedRowData}
                      />     
                    </Grid>
                  </>
                }
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <RolleForm selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} roller={roller} />
                </Grid>
                { selectedRowData.aktiv &&
                  <>
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                      <CheckInput
                        name="foererkort"
                        label="Førerkort"
                        currData={selectedRowData}
                        setCurrData={setSelectedRowData}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                      <Divider/>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                      <InputText
                        name="stilling"
                        label="Stilling"
                        value={selectedRowData.stilling}
                        onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                      />
                    </Grid>
                  </>
                }              
              </Grid>             
          </Paper>

        </Grid>        
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjekttilknytning</Typography>
            {selectedRowData.organisasjon ?
              <RelProsjekt prosjektData={prosjektData} navigate={navigate} />
              :
              <>
                <Button color="primary" startIcon={<AddIcon />} onClick={() => nyttEngasjement()}>
                  Nytt engasjement
                </Button>
                <EngasjementModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} engasjementData={engasjementData} reload={getListData} />
                <EngasjementList engasjement={engasjement} navigate={navigate} />
              </>
            }
          </Paper>

          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>{selectedRowData.organisasjon ? 'Sakstilknytning' : 'Historikk'}</Typography>
              { selectedRowData.organisasjon &&
                <RelSak sakData={sakData} navigate={navigate} />
              }
          </Paper>

        </Grid>
      </Grid>
    </div>
  );
};
