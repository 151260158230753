import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import Header from '../components/Header';
import { LoadingMessage } from '../components/Loading';
import Table from '../components/Table';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { CreateRecordModal } from '../components/Modals';
import SesongEdit from '../components/SesongEdit';
import { renderBooleanCell } from '../components/rendering';

const SesongInfo = () => (
  <Typography paragraph>
    Info om Sesong.
  </Typography>
);

const SesongIngen = () => (
  <Typography paragraph>
    Ingen sesong er valgt. Opprett nytt, eller velg eksisterende sesong i tabellen.
  </Typography>
);

const Sesong = () => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [apiQuery, setApiQuery] = useState(null);
  const tab = useStore(state => state.tab);
  const filterValue = useStore(state => state.filterValue);
  const currActor = useStore(state => state.currActor);  
  const selectedSesongUUID = useStore.getState().selectedSesongUUID;
  const setSelectedSesongUUID = useStore(state => state.setSelectedSesongUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);

  console.log("Sesong rendered")
  console.log("Tab: ", tab)
  console.log("SesongUUID: ", selectedSesongUUID)

  const apiTable = 'v_sesong'
  const editTable = 'edit_sesong'
  const apiId = 'uuid'

  useEffect(() => {
    switch (filterValue) {
      case 'alle':
        setApiQuery(`${apiTable}`);
        break;
      case 'aktive':
        setApiQuery(`${apiTable}?avslutta=neq.true`);
        break;
      case 'mine':
        if (!currActor) {
          setApiQuery(`${apiTable}`);
          console.log("currActor is null")
          break;
        }
        setApiQuery(`${apiTable}?or=(innmeldt_av.eq.${currActor.uuid},utgravningsleder_uuid.cs.{${currActor.uuid}})`);
        break;
      default:
        setApiQuery(`${apiTable}`);
        break;
    }
    console.log("filterValue has changed:", filterValue)
  }, [filterValue]);


  useEffect(() => {
    if (!apiQuery) return;

    const getData = async () => {
      const data = await makeRequest('get', apiQuery, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiQuery has changed:", apiQuery)
  }, [apiQuery]); 

  const newModalFields = [
      { name: 'navn', label: 'Navn' },
      { name: 'kode', label: 'Kode' }
    ];

  const columns = [
    { field: 'navn', headerName: 'Navn', width: 200 },
    { field: 'kode', headerName: 'Kode', width: 75 },
    { field: 'aar', headerName: 'År', width: 75 },    
    { field: 'utgravningsleder', headerName: 'Utgravningsleder', width: 200 },
    { field: 'fornminnetype', headerName: 'Fornminnetype', width: 200 },
    { field: 'ny_registrert_dato', headerName: 'Registrert', width: 150 },
    { field: 'ny_oensket_oppstart', headerName: 'Ønska oppstart', width: 150 },
    { field: 'varighet', headerName: 'Varighet', width: 150 },
    { field: 'avslutta', headerName: 'Sesong avslutta', type: 'boolean', renderCell: renderBooleanCell, width: 100 },
  ];  

  const SesongTable = () => (
      <Table
        columns={columns}
        apiTable={apiTable}
        apiId={apiId}
        setSelectedRowUUID={setSelectedSesongUUID}
        rowData={rowData}
        filter   
      />
  );

//  if(loading) {
//    return (
//      <LoadingMessage />
//    ); 
//  }

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <Header selectedItem={"Sesong"} />
      {tab === 0 && (selectedSesongUUID ? <SesongInfo /> : <SesongIngen />)}
      {tab === 1 && 
        <SesongEdit 
          apiTable={apiTable}
          editTable={editTable}          
          apiId={apiId}
        />
      }      
      {tab === 2 && <SesongTable />}
      <CreateRecordModal 
        fields={newModalFields} 
        apiTable={editTable}
      />
    </div>
  );
}

export default Sesong;
