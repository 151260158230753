import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import Header from '../components/Header';
import { LoadingMessage } from '../components/Loading';
import Table from '../components/Table';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { CreateRecordModal } from '../components/Modals';
import AktoerEdit from '../components/AktoerEdit';
import AktoerView from '../components/AktoerView';
import { renderBooleanCell } from '../components/rendering';

const AktoerIngen = () => (
  <Typography paragraph>
    Ingen aktør er valgt. Opprett ny, eller velg eksisterende aktør i tabellen.
  </Typography>
);

const Aktoer = () => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const tab = useStore(state => state.tab);

  const selectedAktoerUUID = useStore.getState().selectedAktoerUUID;
  const setSelectedAktoerUUID = useStore(state => state.setSelectedAktoerUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);


  console.log("Aktør rendered")
  console.log("Tab: ", tab)
  console.log("AktørUUID: ", selectedAktoerUUID)

  const apiTable = 'v_aktoer'
  const editTable = 'edit_aktoer'
  const apiId = 'uuid'

  useEffect(() => {
    const getData = async () => {
      const data = await makeRequest('get', apiTable, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiTable has changed:", apiTable)
  }, [apiTable]);

  const newModalFields = [
      { name: 'fornavn', label: 'Fornavn' },
      { name: 'etternavn', label: 'Etternavn' }
    ];

  // Helper function that returns a string representation of the "roller" object
  const getRollerString = (roller) => {
    if (!roller) return '';
    return Object.entries(roller)
      .filter(([, value]) => value)
      .map(([key]) => key)
      .join(', ');
  };
  const getObjectString = (field) => {
    if (!field) return '';
    if(field.kontakt) return field.kontakt;

    return JSON.stringify(field);
  };
  const columns = [
    { field: 'navn', headerName: 'Navn', width: 200, 
      renderCell: (params) => params?.row?.organisasjon ? params.row.org_navn : params?.row?.navn,
    },
    { field: 'initial', headerName: 'Initialer', width: 75 },
    { field: 'roller', headerName: 'Roller', width: 200, 
      valueGetter: (params) => getRollerString(params?.row?.roller),
      renderCell: (params) => params?.row?.roller ? renderChipsFromObject(params.row.roller) : null,
    },
    { field: 'ansattnr', headerName: 'Ansattnummer', width: 100 },
    { field: 'avdeling', headerName: 'Avdeling', width: 150 },
    { field: 'epost', headerName: 'E-post', width: 200,
      valueGetter: (params) => getObjectString(params?.row?.epost), },
    { field: 'telefon', headerName: 'Telefon', width: 100, 
      valueGetter: (params) => getObjectString(params?.row?.telefon), },
    { field: 'adresse', headerName: 'Adresse', width: 200,
      valueGetter: (params) => getObjectString(params?.row?.adresse), },
    { field: 'organisasjon', headerName: 'Organisasjon', type: 'boolean', renderCell: (params) => renderBooleanCell(params?.row), width: 100 },

  ];  

  const AktoerTable = () => (
      <Table
        columns={columns}
        apiTable={apiTable}
        apiId={apiId}
        setSelectedRowUUID={setSelectedAktoerUUID}
        rowData={rowData}
      />      
  );

  const renderChipsFromObject = (obj) => {
    if (!obj) return null;
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2px',  alignItems: 'center' }}>
        {Object.entries(obj).map(([key, value]) => {
          if (value) {
            return ( 
              <Chip 
                key={key} 
                label={key} 
                size="small"
                sx={{
                  height: '18px',          // Smaller height
                  fontSize: '0.7rem',      // Smaller font size
                  padding: '2px 0',        // Smaller padding
                  marginLeft: '0px',       // Adjust spacing to your preference
                  marginRight: '0px'       // Adjust spacing to your preference
                }}
              />
            );
          }
          return null;
        })}
      </div>
    );
  };

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <Header selectedItem={"Aktør"} />
      {tab === 0 && (selectedAktoerUUID ? 
        <AktoerView 
        apiTable={apiTable}
        apiId={apiId}        
        /> : <AktoerIngen />)}
      {tab === 1 && 
        <AktoerEdit 
          apiTable={apiTable}
          editTable={editTable}          
          apiId={apiId}
        />
      }
      {tab === 2 && <AktoerTable />}
      <CreateRecordModal 
        fields={newModalFields} 
        apiTable={apiTable}
      />
    </div>
  );
}

export default Aktoer;
