import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { LoadingMessage } from '../components/Loading';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { EngasjementList } from '../components/Engasjement';
import { RelSak, RelProsjekt } from '../components/SakModules';
import { 
  ViewText,
  ViewBoolean,
  ViewBoolList,
  ViewSingleObject,
  ViewMultiObject,
} from '../components/ViewFields';

const MinSideHeader = () => {

  return (
    <Box sx={{ width: '100%', paddingBottom: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Grid size={{ xs: 3 }}>
            <Box>
              <Typography variant="h6" component="span" sx={{ paddingRight: 2 }}>
                Min Side
              </Typography>
            </Box>
          </Grid> 
          <Grid flexGrow={1}>
          </Grid>                        
        </Grid>
      </Box>
    </Box> 
  );
}

const MinSideIngen = () => (
  <Typography paragraph>
    Ingen informasjon om innlogga bruker. 
  </Typography>
);

const MinSideView = ({ apiTable, apiId }) => {
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [engasjement, setEngasjement] = useState([]);
  const [prosjektData, setProsjektData] = useState([]);   
  const [sakData, setSakData] = useState([]);   
  const setStatus = useStore(state => state.setStatus);
  const userData = useStore(state => state.userData);
  const userRole = useStore(state => state.userRole);
  const currActor = useStore(state => state.currActor);


  const navigate = useNavigate();

  const getListData = async (id) => {
    setLoading(true);
    try {
      const [ engasjementList, prosjektDataList, sakDataList ] = await Promise.all([
        makeRequest('get', `v_engasjement?ansatt_id=eq.${id}`, {}, {}),
        makeRequest(
          'get', 
          `v_prosjekt?select=uuid,prosjektkode,prosjektnavn,prosjektleder,askeladden_id,rapport_ferdig,regnskap_signert,feltarbeid_avslutta&or=(prosjektleder_uuid.eq.${id},tiltakshaver_uuid.eq.${id})`, 
          {}, 
          {}
        ),
        makeRequest(
          'get', 
          `v_sak?select=uuid,saksnavn,saksnummer,saksbehandler,paragraf,sak_inn,kommentar,frist,sted_samlet,ask_id&or=(saksbehandler_uuid.cs.{${id}},tidligere_saksbh_uuid.cs.{${id}},tiltakshaver_uuid.eq.${id})`, 
          {}, 
          {}
        ),   
      ]);
      setEngasjement(engasjementList);
      setProsjektData(prosjektDataList);
      setSakData(sakDataList);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
    }
    setLoading(false);
  };


  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        console.log('Running handleRowselected')
        console.log(uuid)
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        setSelectedRowData(rowData[0] || {});
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }      
    } else {
      setSelectedRowData({});
    }
    setLoading(false);
  };

  useEffect(() => {
    if(currActor){
      console.log("CurrAktør: ", currActor)
      console.log("Brukerdata: ", userData)
      handleRowSelected(currActor.uuid);
      getListData(currActor.uuid);
      setLoading(false);
    };
    setStatus(null);
  }, [currActor]);

  if(loading) {
    return <LoadingMessage />; 
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Personinformasjon
            </Typography>
            <Stack spacing={3}>
              <Stack spacing={2}>
                <ViewText title="Navn" data={selectedRowData.navn} />
                <ViewText title="Initialer" data={selectedRowData.initial} />
                <ViewText title="Født" data={selectedRowData.foedt_dato} />
                <ViewSingleObject title="E-post" data={selectedRowData.epost} />
                <ViewSingleObject title="Telefon" data={selectedRowData.telefon} />
                <ViewMultiObject title="Adresse" data={selectedRowData.adresse} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Brukerinformasjon
            </Typography>
            <Typography>
              Brukerdata registrert i Keycloak.
            </Typography>            
            <Stack spacing={3}>
              <Stack spacing={2}>
                <ViewText title="Navn" data={userData.name} />
                <ViewText title="E-post" data={userData.email} />
                <ViewBoolean title="E-post bekrefta" data={userData.email_verified} />
                <ViewText title="Brukernavn" data={userData.preferred_username} />
                <ViewText title="Rolletilgang" data={userRole} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>        
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Arbeidsforhold
            </Typography>
            <Stack spacing={3}>
                <Stack spacing={2}>
                  <ViewText title="Ansattnummer" data={selectedRowData.ansattnr} />
                  <ViewText title="Avdeling" data={selectedRowData.avdeling} />
                  <ViewBoolean title="Aktiv feltarbeider" data={selectedRowData.aktiv} />
                  {selectedRowData.aktiv && (
                    <>
                      <ViewBoolean title="Har Førerkort" data={selectedRowData.foererkort} />
                      <ViewText title="Stilling" data={selectedRowData.stilling} />                       
                    </>
                  )}
                </Stack>
              <ViewBoolList title="Roller" data={selectedRowData.roller} />
            </Stack>
          </Paper>
        </Grid>
        {prosjektData.length > 0 &&
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjekter</Typography>
              <RelProsjekt prosjektData={prosjektData} navigate={navigate} />
          </Paper>
        </Grid>
        }
        {sakData.length > 0 &&
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Saker</Typography>
                <RelSak sakData={sakData} navigate={navigate} />
          </Paper>
        </Grid>
        }
        {engasjement.length > 0 &&
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjekter</Typography>
              <EngasjementList engasjement={engasjement} navigate={navigate} />
          </Paper>
        </Grid>
        }
      </Grid>
    </div>
  );
};

const MinSide = () => {
  const currActor = useStore(state => state.currActor);

  console.log("Minside rendered")

  const apiTable = 'v_aktoer'
  const apiId = 'uuid'

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <MinSideHeader />
      {currActor ? 
        <MinSideView 
        apiTable={apiTable}
        apiId={apiId}        
        /> 
        : 
        <MinSideIngen />
        }
    </div>
  );
}

export default MinSide;
