// ViewFields.jsx
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { RenderBooleanView } from '../components/rendering';


export const ViewText = ({ title, data }) => {
  if (!data) return null;
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      <Typography>{data}</Typography>
    </Box>
  );
};

export const ViewBoolean = ({ title, data }) => {
  if (!data) return null;
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      <Typography>
        <RenderBooleanView value={data} />
      </Typography>
    </Box>
  );
};

export const ViewBoolList = ({ title, data }) => {
  if (!data) return null;
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      <Stack direction="row"  spacing={0} sx={{ flexWrap: 'wrap' }}>
      {data && Object.keys(data).map(key => (
        data[key] && <Chip key={key} variant="outlined" label={key} />
      ))}
      </Stack>
    </Box>
  );
}

export const ViewSingleObject = ({ title, data }) => {
  if (!data) return null;
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      {data && Object.entries(data).map(([key, value]) => (
        <Box key={key}>
        {typeof value !== 'object' ? (
          <Typography key={key}>{`${value} (${key})`}</Typography>
        ) : (
          <>  
            <Typography variant="subtitle2" sx={{ fontStyle: 'italic' }}>{key}:</Typography>
            {Object.entries(value).map(([field, subvalue]) => (
              <Typography key={field}>{`${subvalue} (${field})`}</Typography>
            ))}
          </>
        )
        }
        </Box>
      ))}
    </Box>
  );
}

export const ViewMultiObject = ({ title, data }) => {
  if (!data) return null;
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      {data && Object.entries(data).map(([key, value]) => (
        <Box key={key}>
        {typeof value !== 'object' ? (
          <Typography key={key}>{`${value}`}</Typography>
        ) : (
          <>  
            <Typography variant="subtitle2" sx={{ fontStyle: 'italic' }}>{key}:</Typography>
            {Object.entries(value).map(([field, subvalue]) => (
              <Typography key={field} sx={{ paddingLeft: 2 }}>{`${subvalue}`}</Typography>
            ))}
          </>
        )
        }
        </Box>
      ))}
    </Box>
  );
}