import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash'; 
import Masonry from '@mui/lab/Masonry';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import { AskeladdenDraggable } from '../components/MapModal';
import { LoadingMessage } from '../components/Loading';
import { InnmeldingModal, RolleModal } from '../components/Modals';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import useCrudActions from '../components/crudActions';
import { RelSak, SesongInfo } from './SakModules';
import { FristElement, FristModal } from '../components/Frister';
import KeyValueEditor from './KeyValueEditor';
import { 
  InputText, 
  InputTextMulti, 
  SingleSelect,
  MultiSelect,
  InputDate,
  CheckInput
} from '../components/InputFields';
import { 
  handleInputChange,
  handleMultiAutocompleteChange
} from '../helpers/buttonActions';

const ProsjektIngen = () => (
  <Typography paragraph>
    Inget prosjekt er valgt. Opprett nytt, eller velg eksisterende prosjekt i tabellen.
  </Typography>
);

export default function  ProsjektEdit ({apiTable, editTable, apiId}) {
  console.log("Render ProsjektEdit")

  const [loading, setLoading] = useState(false);
  const [prosjektledere, setProsjektledere] = useState([]);
  const [tiltakshavere, setTiltakshavere] = useState([]);
  const [finansieringskilder, setFinansieringskilder] = useState([]);
  const [digitaleRessurser, setDigitaleRessurser] = useState([]);
  const [sak, setSak] = useState([]);
  const [sakData, setSakData] = useState([]);
  const [sesongData, setSesongData] = useState([]);
  const [adminValg, setAdminValg] = useState([]);
  const selectedProsjektUUID = useStore(state => state.selectedProsjektUUID);
  const setSelectedProsjektUUID = useStore(state => state.setSelectedProsjektUUID);
  const [selectedRowData, setSelectedRowData] = useState({}); 
  const [openInnmeldingModal, setOpenInnmeldingModal] = useState(false);
  const [openFristModal, setOpenFristModal] = useState(false);
  const [openRolleModal, setOpenRolleModal] = useState(false);
  const [rolleType, setRolleType] = useState(null);
  const setStatus = useStore(state => state.setStatus);


  let combinedAskIds = [];
  let { id } = useParams();
  const navigate = useNavigate();

  const getListData = async () => {
    setLoading(true);
    try{
      const prosjektlederList = await makeRequest('get', 'list_prosjektleder', {}, {});
      setProsjektledere(prosjektlederList);
      const tiltakshaverList = await makeRequest('get', 'list_tiltakshaver', {}, {});
      setTiltakshavere(tiltakshaverList);
      const finansieringskildeList = await makeRequest('get', 'list_finansieringskilde', {}, {});
      setFinansieringskilder(finansieringskildeList);
      const digitalRessursList = await makeRequest('get', 'list_digital_ressurs', {}, {});
      setDigitaleRessurser(digitalRessursList);      
      const sakList = await makeRequest('get', 'list_sak?select=value,label', {}, {});
      setSak(sakList);
      const adminValgList = await makeRequest('get', 'list_admin_valg?select=value,label', {}, {});
      setAdminValg(adminValgList);      
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);
  };

  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        console.log('Running handleRowselected')
        console.log(uuid)
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        if(rowData[0]) {
          setSelectedRowData(rowData[0]);
          setStatus( rowData[0].avslutta_unit4 ? "avslutta" : "pågår" );
        } else {
          setSelectedRowData({});
          setStatus(null);
        };         
        const sesongList = await makeRequest('get', `v_sesong?prosjekt_id=eq.${uuid}`, {}, {});
        setSesongData(sesongList);        
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }      
    } else {
      setSelectedRowData({});
      setStatus(null);
    }
    setLoading(false);
  };

  // Retrieve the CRUD functions from the hook
  const { 
    prosjektleder, 
    kontaktperson_tiltakshaver, 
    feltarbeid_avslutta, 
    ...cleanedRowData 
  } = selectedRowData;  
  
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedProsjektUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedProsjektUUID,
    apiTable: editTable,
    navigate: navigate
  });

  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedProsjektUUID(id);
      handleRowSelected(id);
      getListData();
    } else if(selectedProsjektUUID){
      navigate(selectedProsjektUUID);
      setLoading(false);
    } else {
      setStatus(null);
    };
  }, [id]);  

  useEffect(() => {
    if(id){
      setSelectedProsjektUUID(id);
      handleRowSelected(id);
      getListData();
    } else if(selectedProsjektUUID){
      navigate(selectedProsjektUUID);
      setLoading(false);
    }
  }, [id]); 

  useEffect(() => {
    if (!openRolleModal) { // We only want to get the data on initial render and after the modal is closed - not when it opens
      getListData();
    }
  }, [openRolleModal]);

  const getSakData = async () => {
    try {
      setLoading(true);
      const sakDataList = await makeRequest('get', `v_sak?select=uuid,saksnavn,saksnummer,saksbehandler,paragraf,sak_inn,kommentar,frist,sted_samlet,ask_id&prosjekt_ids=cs.{${id}}`, {}, {});
      setSakData(sakDataList);         
      setLoading(false);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
  };

  useEffect(() => {
    getSakData();
  }, [selectedRowData]);

  combinedAskIds = useMemo(() => {
    if (Array.isArray(sakData)) {
      return sakData.reduce((acc, obj) => {
        if (Array.isArray(obj.ask_id)) {
          acc = [...acc, ...obj.ask_id.filter(option => option != null)];
        }
        return acc;
      }, []);
    }
    return [];
  }, [sakData]);

  const newProsjektleder = (event) => {
    console.log("Legger til ny prosjektlederrolle.");
    setRolleType('prosjekt')
    setOpenRolleModal(true);
  };

  const newTiltakshaver = (event) => {
    console.log("Legger til ny tiltakshaverrolle.");
    setRolleType('tiltakshaver')
    setOpenRolleModal(true);
  };  

  const newSesong = (event) => {
    console.log("Åpner innmeldingsmodal");
    setOpenInnmeldingModal(true);
    //navigate('/Prosjekt');
  };

  const newFrist = (event) => {
    console.log("Legger til ny frist.");
    setOpenFristModal(true)
  };

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  if(!id){
    return <ProsjektIngen />
  }

  return(
    <div>
      <Grid container spacing={2} >
        <Grid size={{ sm: 12, md: 6, lg: 6 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjektinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 2 }}>
                  <InputText
                    name="prosjektkode"
                    label="Prosjektkode"
                    value={selectedRowData.prosjektkode}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 8, sm: 4, md: 8, lg: 4, xl: 6 }}>
                  <InputText
                    name="prosjektnavn"
                    label="Prosjektnavn"
                    value={selectedRowData.prosjektnavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 4, md: 12, lg: 4, xl: 4 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="prosjektleder_uuid"
                      label="Prosjektleder"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={prosjektledere}
                      optionLabel="label"
                      optionValue="value"
                      {...selectedRowData.prosjektleder_uuid && { 
                        link: () => navigate(`/Aktør/${selectedRowData.prosjektleder_uuid}`)
                      }}    
                    />
                    <IconButton
                      aria-label="Legg til prosjektleder"
                      onClick={newProsjektleder}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>
               
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <InputTextMulti
                    name="kommentar"
                    label="Kommentar"
                    value={selectedRowData.kommentar}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>                  
              </Grid> 
             
            </form>
          </Paper>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Lokalitetsinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid size={{ xs: 12, sm: 4, md: 12, lg: 4, xl: 4 }}>
                  <Autocomplete
                    multiple
                    freeSolo //Allowing for user adding other Askeladden-ids
                    id="askeladden_id"
                    options={combinedAskIds}//Add wait for loading ??
                    getOptionLabel={(option) => option ? option.toString() : ''} // Dealing with postgres adding a lot of {null}-arrays            
                    value={selectedRowData?.askeladden_id ? selectedRowData.askeladden_id.filter(id => id != null) : []} // Dealing with postgres adding a lot of {null}-arrays
                    onChange={(event, newValue) => handleMultiAutocompleteChange(event, newValue, 'askeladden_id', selectedRowData, setSelectedRowData )}
                    renderOption={(props, option) => <li {...props}>{option}</li>} // Renders each option in the dropdown
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Askeladden-id" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value
                        .filter(option => option != null) // Dealing with postgres adding a lot of {null}-arrays
                        .map((option, index) => (
                        //<Chip variant="outlined" label={option.toString()} {...getTagProps({ index })} />
                        <Chip variant="outlined" label={<AskeladdenDraggable id={option} colour={"primary"} />} {...getTagProps({ index })} />
                      ))
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 8, md: 12, lg: 8, xl: 8 }}>
                  <Autocomplete
                    multiple
                    id="sted_samlet"
                    options={sakData?.sted_samlet?.filter((option) => option != null) || []}
                    getOptionLabel={(option) => option ? `${option.bnr}, ${option.fylke}, ${option.kommune}`: ''}
                    renderInput={(params) => <TextField {...params} label="Sted" variant="outlined" />}
                    value={selectedRowData?.sted_samlet || []}
                    onChange={(event, newValue) => handleMultiAutocompleteChange(event, newValue, 'sted_samlet', selectedRowData, setSelectedRowData)}
                    isOptionEqualToValue={(option, value) => _.isEqual(option, value)}
                  />
                </Grid>                   
              </Grid>
            </form>
          </Paper>  
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }} >              
                <Typography variant='h6'>Saksdata</Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <MultiSelect
                  name="sak_ids"
                  label="Relaterte saker"
                  currData={selectedRowData}
                  setCurrData={setSelectedRowData}
                  options={sak}
                  optionLabel="label"
                  optionValue="value"
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <RelSak 
                  sakData={sakData}
                  navigate={navigate} 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 3 }}>          
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Tilhørende sesong</Typography>
            <Button color="primary" startIcon={<AddIcon />} onClick={newSesong}>
              Meld inn sesong/utgravingsprosjekt
            </Button>
            <SesongInfo 
              sesongData={sesongData} 
              navigate={navigate}
            />
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>          
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Eksterne digitale ressurser</Typography>
              <KeyValueEditor
                name={"digitale_ressurser"}
                jsonData={selectedRowData.digitale_ressurser}
                onSave={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                keyLabel={"Type"}
                valueLabel={"Lenke"}
                keyOptions={digitaleRessurser}
                addLabel={"Legg til ressurs"}
                link
              />
          </Paper>
        </Grid>
        <Grid size={{ sm: 12, md: 6, lg: 6, xl: 3 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Økonomi</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <InputDate
                    name="faktura_sendt"
                    label="Faktura sendt"
                    value={selectedRowData.faktura_sendt}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <InputDate
                    name="faktura_innbetalt"
                    label="Faktura innbetalt"
                    value={selectedRowData.faktura_innbetalt}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <SingleSelect
                    name="delfakturering"
                    label="Skal delfaktureres"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                    options={adminValg}
                    optionLabel="label"
                    optionValue="value"
                  />
                </Grid>
                { // Only show delfaktura_sum if delfakturering is set to 'Ja' or 'Innbetalt'
                (selectedRowData.delfakturering === 'c3619f92-1c55-4d52-b5b8-045361f3bbac' || selectedRowData.delfakturering === '78554520-919e-4b2e-b7f8-e22564e12f53') && (
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <InputText
                    name="delfaktura_sum"
                    label="Delfakturabeløp"
                    value={selectedRowData.delfaktura_sum}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />                                    
                </Grid>
                )}
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <SingleSelect
                    name="konserveringsprosjekt"
                    label="Konserveringsprosjekt"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                    options={adminValg}
                    optionLabel="label"
                    optionValue="value"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <FormGroup>
                    <CheckInput
                      name="rapport_ferdig"
                      label="Rapport ferdig"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                    />
                  </FormGroup>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <FormGroup>
                    <CheckInput
                      name="regnskap_signert"
                      label="Regnskapsrapport sluttsignert"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                    />
                  </FormGroup>
                </Grid>                
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <InputDate
                    name="sluttoppgjoer_sendt"
                    label="Sluttoppgjør sendt"
                    value={selectedRowData.sluttoppgjoer_sendt}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>                
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <InputDate
                    name="avslutta_unit4"
                    label="Prosjekt avslutta"
                    toolTip="Dato for avslutning av prosjektet i Unit4"
                    value={selectedRowData.avslutta_unit4}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="tiltakshaver_uuid"
                      label="Tiltakshaver"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={tiltakshavere}
                      optionLabel="label"
                      optionValue="value"
                      {...selectedRowData.tiltakshaver_uuid && { 
                        link: () => navigate(`/Aktør/${selectedRowData.tiltakshaver_uuid}`)
                      }}
                    />
                    <IconButton
                      aria-label="Legg til tiltakshaver"
                      onClick={newTiltakshaver}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <SingleSelect
                    name="finansieringskilde_uuid"
                    label="Finansieringskilde"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                    options={finansieringskilder}
                    optionLabel="label"
                    optionValue="value"
                  />
                </Grid>                
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <InputTextMulti
                    name="budsjett"
                    label="Budsjett"
                    value={selectedRowData.budsjett}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>                                            
              </Grid> 
            </form>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, md: 3, lg: 3, xl: 3 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Frister</Typography>
            <Button color="primary" startIcon={<AddIcon />} onClick={newFrist}>
              Ny frist
            </Button>
            <FristElement 
              frister={selectedRowData?.frist || []}
              setSelectedRowData={setSelectedRowData}
            />
            <FristModal
              setSelectedRowData={setSelectedRowData}
              open={openFristModal}
              setOpen={setOpenFristModal}
            />
          </Paper>
        </Grid>           
      </Grid>
      <InnmeldingModal
        open={openInnmeldingModal}
        setOpen={setOpenInnmeldingModal}
        selectedRowData={selectedRowData}
        sakData={sakData}
        prosjektledere={prosjektledere}
        handleRowSelected={handleRowSelected}
      />
      <RolleModal
        rolle={rolleType}
        open={openRolleModal}
        setOpen={setOpenRolleModal}
      />       
    </div>
  );
};
